import { __decorate } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import { mainStore, userToolStore, userStore } from '@/utils/store-accessor';
// import { mainStore, userToolStore} from "@/utils/store-accessor";
let List = class List extends Vue {
    constructor() {
        super(...arguments);
        this.headers = [
            {
                text: 'Nome',
                sortable: true,
                value: 'full_name',
                align: 'left',
            },
            {
                text: 'E-mail',
                sortable: true,
                value: 'email',
                align: 'left',
            },
            {
                text: 'CPF',
                sortable: true,
                value: 'cpf',
                align: 'left',
            },
            {
                text: 'Cargo',
                sortable: true,
                value: 'cargo.nome',
                align: 'left',
            },
            {
                text: 'Departamento',
                sortable: true,
                value: 'departamento.nome',
                align: 'left',
            },
            {
                text: 'Ativo',
                sortable: true,
                value: 'is_active',
                align: 'left',
            },
            {
                text: 'Ações',
                value: 'actions',
            },
        ];
        this.search = '';
        this.somenteAtivos = true;
    }
    get allowView() {
        if (userToolStore.userToolsIndexed.Colaboradores) {
            return userToolStore.userToolsIndexed.Colaboradores[0]
                .allow_view;
        }
    }
    get allowCreate() {
        if (userToolStore.userToolsIndexed.Colaboradores) {
            return userToolStore.userToolsIndexed.Colaboradores[0]
                .allow_create;
        }
    }
    get allowEdit() {
        if (userToolStore.userToolsIndexed.Colaboradores) {
            return userToolStore.userToolsIndexed.Colaboradores[0]
                .allow_edit;
        }
    }
    get allowDelete() {
        if (userToolStore.userToolsIndexed.Colaboradores) {
            return userToolStore.userToolsIndexed.Colaboradores[0]
                .allow_delete;
        }
    }
    get colaboradorItems() {
        return userStore.users;
    }
    get userProfile() {
        return mainStore.userProfile;
    }
    async deleteUser(id) {
        await userStore.deleteUser(id);
    }
    async buscar() {
        await userStore.getUsers(this.somenteAtivos);
    }
    async mounted() {
        this.buscar();
    }
    routeEdit(id) {
        this.$router.push({ name: 'main-colaboradores-edit', params: { id } });
    }
};
List = __decorate([
    Component
], List);
export default List;
